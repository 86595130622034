import React, { useState } from "react";

function ManageProducts({ products, setProducts, categories, token }) {
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    description: "",
    category: "",
    stock: "",
    type: "simple",
    variations: [],
  });
  const [image, setImage] = useState(null);
  const [editProductId, setEditProductId] = useState(null);

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddVariation = () => {
    setNewProduct((prev) => ({
      ...prev,
      variations: [...prev.variations, { size: "", price: "" }],
    }));
  };

  const handleVariationChange = (index, field, value) => {
    const updatedVariations = [...newProduct.variations];
    updatedVariations[index][field] = value;
    setNewProduct((prev) => ({ ...prev, variations: updatedVariations }));
  };

  const handleRemoveVariation = (index) => {
    setNewProduct((prev) => ({
      ...prev,
      variations: prev.variations.filter((_, i) => i !== index),
    }));
  };

  const resetForm = () => {
    setEditProductId(null);
    setNewProduct({
      name: "",
      price: "",
      description: "",
      category: "",
      stock: "",
      type: "simple",
      variations: [],
    });
    setImage(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editProductId ? handleUpdateProduct() : handleAddProduct();
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (key === "variations" && newProduct.type === "variable") {
        formData.append(key, JSON.stringify(newProduct[key]));
      } else {
        formData.append(key, newProduct[key]);
      }
    });
    if (image) formData.append("image", image);

    try {
      const res = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/products", {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (res.ok) {
        const addedProduct = await res.json();
        setProducts((prev) => [...prev, addedProduct]);
        resetForm();
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleEditProduct = (product) => {
    setEditProductId(product._id);
    setNewProduct({
      name: product.name,
      price: product.price || "",
      description: product.description,
      category: product.category,
      stock: product.stock,
      type: product.type,
      variations: product.variations || [],
    });
    setImage(null);
  };

  const handleUpdateProduct = async () => {
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (key === "variations" && newProduct.type === "variable") {
        formData.append(key, JSON.stringify(newProduct[key]));
      } else {
        formData.append(key, newProduct[key]);
      }
    });
    if (image) formData.append("image", image);

    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/products/${editProductId}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );

      if (res.ok) {
        const updatedProduct = await res.json();
        setProducts((prev) =>
          prev.map((product) =>
            product._id === editProductId ? updatedProduct : product
          )
        );
        resetForm();
      } else {
        console.error("Failed to update product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/products/${id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.ok) {
        setProducts((prev) => prev.filter((product) => product._id !== id));
      } else {
        console.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
  <h3 className="text-2xl font-semibold mb-4">Manage Products</h3>
  <form
    onSubmit={handleSubmit}
    className="bg-white p-4 shadow-md rounded-md space-y-4"
  >
    <input
      type="text"
      name="name"
      value={newProduct.name}
      onChange={handleProductInputChange}
      placeholder="Product Name"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <select
      name="type"
      value={newProduct.type}
      onChange={handleProductInputChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    >
      <option value="simple">Simple Product</option>
      <option value="variable">Variable Product</option>
    </select>
    {newProduct.type === "simple" && (
      <input
        type="number"
        name="price"
        value={newProduct.price}
        onChange={handleProductInputChange}
        placeholder="Price"
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
      />
    )}
    {newProduct.type === "variable" && (
      <div>
        <h4 className="font-semibold mb-2">Variations</h4>
        {newProduct.variations.map((variation, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 mb-2"
          >
            <input
              type="text"
              value={variation.size}
              onChange={(e) =>
                handleVariationChange(index, "size", e.target.value)
              }
              placeholder="Size"
              className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <input
              type="number"
              value={variation.price}
              onChange={(e) =>
                handleVariationChange(index, "price", e.target.value)
              }
              placeholder="Price"
              className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="button"
              onClick={() => handleRemoveVariation(index)}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddVariation}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Variation
        </button>
      </div>
    )}
    <textarea
      name="description"
      value={newProduct.description}
      onChange={handleProductInputChange}
      placeholder="Description"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <select
      name="category"
      value={newProduct.category}
      onChange={handleProductInputChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    >
      <option value="">Select Category</option>
      {categories.map((cat) => (
        <option key={cat._id} value={cat._id}>
          {cat.name}
        </option>
      ))}
    </select>
    <input
      type="number"
      name="stock"
      value={newProduct.stock}
      onChange={handleProductInputChange}
      placeholder="Stock"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <input
      type="file"
      onChange={handleImageChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    <button
      type="submit"
      className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600"
    >
      {editProductId ? "Update Product" : "Add Product"}
    </button>
  </form>

  <div className="mt-8 space-y-4">
    {products.map((product) => (
      <div
        key={product._id}
        className="flex items-center justify-between bg-white p-4 shadow-md rounded-md"
      >
        <img
          src={product.picture}
          alt={product.name}
          className="w-24 h-24 object-cover rounded-md"
        />
        <div className="flex-1 ml-4">
          <p className="text-lg font-bold">{product.name}</p>
          <p className="text-gray-600">
            {product.price || "Variable Product"}
          </p>
          {product.isVariable && (
            <div>
              <h4 className="font-semibold">Variations:</h4>
              {product.variations.map((variation, index) => (
                <p key={index} className="text-gray-600">
                  {variation.size} - ${variation.price}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handleEditProduct(product)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteProduct(product._id)}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    ))}
  </div>
</div>

  );
}

export default ManageProducts;
