import React, { useState } from "react";

const ManageCoupons = ({ coupons,setCoupons,token}) => {
  const [newCoupon, setNewCoupon] = useState({
    code: "",
    discountType: "percentage",
    discountValue: 0,
    expirationDate: "",
    usageLimit: 1,
  });
  const [editCouponId, setEditCouponId] = useState(null);
  const handleAddCoupon = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/coupons", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...newCoupon,
          expirationDate: new Date(newCoupon.expirationDate).toISOString(),
        }),
      });
      if (res.ok) {
        const addedCoupon = await res.json();
        setCoupons((prev) => [...prev, addedCoupon]);
        setNewCoupon({ code: "", discountType: "percentage", discountValue: 0, expirationDate: "", usageLimit: 1 });
      } else {
        const errorData = await res.json();
        console.error("Failed to add coupon:", errorData.message);
      }
    } catch (error) {
      console.error("Error adding coupon:", error);
    }
  };
  
const handleDeleteCoupon = async (id) => {
    try {
      const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/coupons/${id}`, {
        method: "DELETE",
        headers:{"Content-Type": "application/json",
          "Authorization": `Bearer ${token}`} 
      });
      if (res.ok) {
        setCoupons(coupons.filter((coupon) => coupon._id !== id));
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
    <h3 className="text-2xl font-bold mb-6">Manage Coupons</h3>
  
    {/* Coupon Form */}
    <form
      onSubmit={handleAddCoupon}
      className="space-y-4 bg-gray-800 p-4 rounded-lg shadow-md"
    >
      <div>
        <input
          type="text"
          value={newCoupon.code}
          onChange={(e) =>
            setNewCoupon({ ...newCoupon, code: e.target.value })
          }
          placeholder="Coupon Code"
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        />
      </div>
  
      <div>
        <select
          value={newCoupon.discountType}
          onChange={(e) =>
            setNewCoupon({ ...newCoupon, discountType: e.target.value })
          }
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        >
          <option value="percentage">Percentage</option>
          <option value="fixed">Fixed</option>
        </select>
      </div>
  
      <div>
        <input
          type="number"
          value={newCoupon.discountValue}
          onChange={(e) =>
            setNewCoupon({
              ...newCoupon,
              discountValue: Number(e.target.value),
            })
          }
          placeholder="Discount Value"
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        />
      </div>
  
      <div>
        <input
          type="date"
          value={newCoupon.expirationDate}
          onChange={(e) =>
            setNewCoupon({ ...newCoupon, expirationDate: e.target.value })
          }
          placeholder="Expiration Date"
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        />
      </div>
  
      <div>
        <input
          type="number"
          value={newCoupon.usageLimit}
          onChange={(e) =>
            setNewCoupon({
              ...newCoupon,
              usageLimit: Number(e.target.value),
            })
          }
          placeholder="Usage Limit"
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        />
      </div>
  
      <button
        type="submit"
        className="w-full bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded shadow"
      >
        Add Coupon
      </button>
    </form>
  
    {/* Coupons List */}
    <ul className="mt-6 space-y-4">
      {coupons.map((coupon) => (
        <li
          key={coupon._id}
          className="flex justify-between items-center p-4 bg-gray-800 rounded-lg shadow-md"
        >
          <div>
            <p className="font-bold">{coupon.code}</p>
            <p>
              Discount:{" "}
              <span className="text-blue-400">
                {coupon.discountType === "percentage"
                  ? `${coupon.discountValue}%`
                  : `$${coupon.discountValue}`}
              </span>
            </p>
            <p className="text-gray-400 text-sm">
              Expires on: {new Date(coupon.expirationDate).toLocaleDateString()}
            </p>
            <p className="text-gray-400 text-sm">
              Usage Limit: {coupon.usageLimit}
            </p>
          </div>
          <button
            onClick={() => handleDeleteCoupon(coupon._id)}
            className="px-3 py-1 bg-red-600 hover:bg-red-500 text-white rounded shadow"
          >
            Delete
          </button>
        </li>
      ))}
    </ul>
  </div>
  
  );
};

export default ManageCoupons;
