import React, { useState } from "react";

const ManageOrders = ({ orders, setOrders,token}) => {
    const [editOrder, setEditOrder] = useState(null);
    
    const handleDeleteOrder = async (id) => {
        try {
          const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/orders/${id}`, {
            method: "DELETE",
            headers: { Authorization: `Bearer ${token}` },
          });
          if (res.ok) setOrders(orders.filter((order) => order._id !== id));
        } catch (error) {
          console.error("Error deleting order:", error);
        }
      };    
    const handleEditOrder = (order) => {
        setEditOrder(order);
      };
      
      const handleUpdateOrder = async () => {
        try {
          const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/orders/${editOrder._id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
            body: JSON.stringify(editOrder),
          });
      
          if (res.ok) {
            // The order was successfully updated on the backend
            setOrders((prevOrders) =>
              prevOrders.map((order) =>
                order._id === editOrder._id ? { ...order, status: editOrder.status } : order
              )
            );
            setEditOrder(null); // Reset editOrder after updating
          }
        } catch (error) {
          console.error("Error updating order:", error);
        }
      };
      
  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
  <h3 className="text-2xl font-bold mb-6">Manage Orders</h3>

  {orders.length > 0 ? (
    orders.map((order) => (
      <div
        key={order._id}
        className="mb-6 p-4 bg-gray-800 rounded-lg shadow-lg"
      >
        {/* Order Header */}
        <div className="flex justify-between items-center">
          <p className="text-lg font-bold">
            Order #{order._id} - 
            <span
              className={`ml-2 px-2 py-1 rounded text-sm ${
                order.status === "pending"
                  ? "bg-yellow-500"
                  : order.status === "shipped"
                  ? "bg-blue-500"
                  : order.status === "delivered"
                  ? "bg-green-500"
                  : "bg-red-500"
              }`}
            >
              {order.status}
            </span>
          </p>
          <div className="space-x-2">
            <button
              onClick={() => handleEditOrder(order)}
              className="px-3 py-1 bg-blue-600 hover:bg-blue-500 text-white rounded shadow"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeleteOrder(order._id)}
              className="px-3 py-1 bg-red-600 hover:bg-red-500 text-white rounded shadow"
            >
              Delete
            </button>
          </div>
        </div>

        {/* Order Details */}
        <div className="mt-4">
          <h4 className="text-lg font-semibold">Items:</h4>
          <ul className="list-disc list-inside text-gray-400 mt-2">
            {order.items.map((item) => (
              <li key={item._id} className="mb-1">
                {item.name} - Quantity: {item.quantity} - Price: ${item.price}
              </li>
            ))}
          </ul>

          <div className="mt-4 text-gray-300">
            <p>Customer First Name: <span className="font-semibold">{order.buyer?.firstName || "N/A"}</span></p>
            <p>Email: <span className="font-semibold">{order.buyer?.email || "N/A"}</span></p>
            <p>Total: <span className="font-semibold">${order.totalAmount}</span></p>
            <p>Address: <span className="font-semibold">{order.buyer?.streetAddress || "N/A"}</span></p>
          </div>
        </div>

        {/* Edit Order Section */}
        {editOrder && editOrder._id === order._id && (
          <div className="mt-4 bg-gray-700 p-4 rounded-lg">
            <label className="block text-sm font-medium mb-2">Update Status</label>
            <select
              value={editOrder.status}
              onChange={(e) =>
                setEditOrder({ ...editOrder, status: e.target.value })
              }
              className="w-full p-2 bg-gray-800 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
            >
              <option value="pending">Pending</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="canceled">Canceled</option>
            </select>
            <button
              onClick={handleUpdateOrder}
              className="mt-4 w-full bg-green-600 hover:bg-green-500 text-white font-medium py-2 px-4 rounded shadow"
            >
              Save
            </button>
          </div>
        )}
      </div>
    ))
  ) : (
    <p className="text-gray-400 text-center">No orders available</p>
  )}
</div>

  );
};

export default ManageOrders;
