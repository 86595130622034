import React, { useState } from "react";

const ManageShippingMethods = ({ shippingMethods,token,setShippingMethods}) => {
  const [newShippingMethod, setNewShippingMethod] = useState({
    name: "",
    cost: "",
    estimatedDeliveryTime: "",
    isActive: true, // defaulting to true
  });
  const handleDeleteShippingMethod = async (id) => {
    try {
      const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/shipping_methods/${id}`, {
        method: "DELETE",
        headers:{"Content-Type": "application/json",
          "Authorization": `Bearer ${token}`} 
      });
      if (res.ok) {
        setShippingMethods(shippingMethods.filter((method) => method._id !== id));
      }
    } catch (error) {
      console.error("Error deleting shipping method:", error);
    }
  };
  const handleAddShippingMethod = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/shipping_methods", {
        method: "POST",
        headers: {"Content-Type": "application/json",
          "Authorization": `Bearer ${token}`},
        body: JSON.stringify(newShippingMethod),
      });
      if (res.ok) {
        const addedShippingMethod = await res.json();
        setShippingMethods((prev) => [...prev, addedShippingMethod]);
        setNewShippingMethod({ name: "", cost: "", estimatedDeliveryTime: "", isActive: true });

      }
    } catch (error) {
      console.error("Error adding shipping method:", error);
    }
  };
  return (
    <div>
     <h3>Manage Shipping Methods</h3>
      <form onSubmit={handleAddShippingMethod}>
  <input
    type="text"
    value={newShippingMethod.name}
    onChange={(e) => setNewShippingMethod({ ...newShippingMethod, name: e.target.value })}
    placeholder="Shipping Method Name"
    required
  />
  <input
    type="number"
    value={newShippingMethod.cost}
    onChange={(e) => setNewShippingMethod({ ...newShippingMethod, cost: parseFloat(e.target.value) })}
    placeholder="Cost"
    required
  />
  <input
    type="text"
    value={newShippingMethod.estimatedDeliveryTime}
    onChange={(e) => setNewShippingMethod({ ...newShippingMethod, estimatedDeliveryTime: e.target.value })}
    placeholder="Estimated Delivery Time (e.g., '3-5 days')"
    required
  />
  <label>
    <input
      type="checkbox"
      checked={newShippingMethod.isActive}
      onChange={(e) => setNewShippingMethod({ ...newShippingMethod, isActive: e.target.checked })}
    />
    Active
  </label>
  <button type="submit">Add Shipping Method</button>
</form>

      <ul>
        {shippingMethods.map((method) => (
          <li key={method._id}>
            {method.name} - ${method.cost}
            <button onClick={() => handleDeleteShippingMethod(method._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageShippingMethods;
