import React, { useEffect, useState } from "react";

function ManageReviews({ token }) {
  const [reviews, setReviews] = useState([]);
  const [editingReview, setEditingReview] = useState(null); // Track the review being edited
  const [editForm, setEditForm] = useState({
    name: "",
    rating: "",
    review: "",
    date: "",
    approved: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(5); // Number of reviews per page

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/reviews", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setReviews(data);
        } else {
          console.error("Error fetching reviews:", await response.text());
        }
      } catch (error) {
        console.error("Network error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [token]);

  const handleDelete = async (reviewId) => {
    try {
      const response = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/reviews/${reviewId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setReviews((prevReviews) =>
          prevReviews.filter((review) => review._id !== reviewId)
        );
      }
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  const handleEdit = (review) => {
    setEditingReview(review._id);
    setEditForm({
      name: review.name,
      rating: review.rating,
      review: review.review,
      date: new Date(review.date).toISOString().slice(0, 10), // Format for editing
      approved: review.approved,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/reviews/${editingReview}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editForm),
        }
      );

      if (response.ok) {
        const updatedReview = await response.json();
        setReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === updatedReview._id
              ? updatedReview
              : review
          )
        );
        setEditingReview(null);
      } else {
        console.error("Error updating review:", await response.text());
      }
    } catch (error) {
      console.error("Network error updating review:", error);
    }
  };

  // Pagination Logic
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold text-white">Manage Reviews</h2>
      {reviews.length === 0 ? (
        <p className="text-gray-500">No reviews available.</p>
      ) : (
        <>
          <table className="min-w-full bg-gray-800 text-gray-200 border-collapse">
            <thead>
              <tr>
                <th className="p-2 border-b">Product</th>
                <th className="p-2 border-b">Name</th>
                <th className="p-2 border-b">Rating</th>
                <th className="p-2 border-b">Review</th>
                <th className="p-2 border-b">Date</th>
                <th className="p-2 border-b">Status</th>
                <th className="p-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
  {currentReviews.map((review) => {
    // Check if this is the review being edited
    const isEditing = editingReview === review._id;

    return isEditing ? (
      <tr key={review._id}>
        <td className="p-2 border-b">{review.product.name}</td>
        <td className="p-2 border-b">
          <input
            type="text"
            value={editForm.name}
            onChange={(e) =>
              setEditForm({ ...editForm, name: e.target.value })
            }
            className="bg-gray-700 p-2 rounded"
          />
        </td>
        <td className="p-2 border-b">
          <input
            type="number"
            value={editForm.rating}
            onChange={(e) =>
              setEditForm({ ...editForm, rating: e.target.value })
            }
            className="bg-gray-700 p-2 rounded"
            min="1"
            max="5"
          />
        </td>
        <td className="p-2 border-b">
          <textarea
            value={editForm.review}
            onChange={(e) =>
              setEditForm({ ...editForm, review: e.target.value })
            }
            className="bg-gray-700 p-2 rounded"
          />
        </td>
        <td className="p-2 border-b">
          <input
            type="date"
            value={editForm.date}
            onChange={(e) =>
              setEditForm({ ...editForm, date: e.target.value })
            }
            className="bg-gray-700 p-2 rounded"
          />
        </td>
        <td className="p-2 border-b">
          <select
            value={editForm.approved}
            onChange={(e) =>
              setEditForm({
                ...editForm,
                approved: e.target.value === "true",
              })
            }
            className="bg-gray-700 p-2 rounded"
          >
            <option value="true">Approved</option>
            <option value="false">Pending</option>
          </select>
        </td>
        <td className="p-2 border-b">
          <button
            className="text-green-500 mr-2"
            onClick={handleEditSubmit}
          >
            Save
          </button>
          <button
            className="text-yellow-500"
            onClick={() => setEditingReview(null)}
          >
            Cancel
          </button>
        </td>
      </tr>
    ) : (
      <tr key={review._id}>
        <td className="p-2 border-b">{review.product.name}</td>
        <td className="p-2 border-b">{review.name}</td>
        <td className="p-2 border-b">{review.rating}</td>
        <td className="p-2 border-b">{review.review}</td>
        <td className="p-2 border-b">
          {new Date(review.date).toLocaleDateString()}
        </td>
        <td className="p-2 border-b">
          {review.approved ? (
            <span className="text-green-500">Approved</span>
          ) : (
            <span className="text-red-500">Pending</span>
          )}
        </td>
        <td className="p-2 border-b">
          <button
            className="text-blue-500 mr-2"
            onClick={() => handleEdit(review)}
          >
            Edit
          </button>
          <button
            className="text-red-500"
            onClick={() => handleDelete(review._id)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  })}
</tbody>

          </table>
          {/* Pagination Controls */}
          <div className="flex justify-center mt-4 space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`px-3 py-1 border rounded ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-700 text-gray-200"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ManageReviews;
