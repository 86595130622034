import React, { useState, useEffect } from "react";

function ManageDiet({ diets, setDiets, token }) {
  const [dietName, setDietName] = useState("");
  const [dietDescription, setDietDescription] = useState("");
  const [editingDietId, setEditingDietId] = useState(null);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetchDiets();
  }, []);

  const fetchDiets = async () => {
    try {
      const res = await fetch(
        "https://supplement-app-d151447d38d4.herokuapp.com/api/admin/diets",
        { headers }
      );
      if (res.ok) {
        const data = await res.json();
        setDiets(data);
      } else {
        console.error("Error fetching diets:", await res.text());
      }
    } catch (error) {
      console.error("Network error fetching diets:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dietData = {
      name: dietName,
      description: dietDescription,
    };

    try {
      const url = editingDietId
        ? `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/diets/${editingDietId}`
        : "https://supplement-app-d151447d38d4.herokuapp.com/api/diets";

      const method = editingDietId ? "PUT" : "POST";

      const res = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(dietData),
      });

      if (res.ok) {
        const updatedDiet = await res.json();
        if (editingDietId) {
          // Update diet in the list
          setDiets((prevDiets) =>
            prevDiets.map((diet) =>
              diet._id === updatedDiet._id ? updatedDiet : diet
            )
          );
        } else {
          // Add new diet to the list
          setDiets((prevDiets) => [...prevDiets, updatedDiet]);
        }
        setDietName("");
        setDietDescription("");
        setEditingDietId(null);
      } else {
        console.error("Error saving diet:", await res.text());
      }
    } catch (error) {
      console.error("Network error saving diet:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/diets/${id}`,
        {
          method: "DELETE",
          headers,
        }
      );

      if (res.ok) {
        setDiets((prevDiets) => prevDiets.filter((diet) => diet._id !== id));
      } else {
        console.error("Error deleting diet:", await res.text());
      }
    } catch (error) {
      console.error("Network error deleting diet:", error);
    }
  };

  const handleEdit = (diet) => {
    setDietName(diet.name);
    setDietDescription(diet.description);
    setEditingDietId(diet._id);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Diets</h2>

      {/* Add/Edit Diet Form */}
      <form
        className="mb-4 bg-gray-800 p-4 rounded-md"
        onSubmit={handleSubmit}
      >
        <div className="mb-2">
          <label className="block text-gray-300">Diet Name</label>
          <input
            type="text"
            value={dietName}
            onChange={(e) => setDietName(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            placeholder="Enter diet name"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Diet Description</label>
          <textarea
            value={dietDescription}
            onChange={(e) => setDietDescription(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            placeholder="Enter diet description"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          {editingDietId ? "Update Diet" : "Add Diet"}
        </button>
      </form>

      {/* Diets List */}
      <table className="w-full bg-gray-800 text-gray-300 rounded-md">
        <thead>
          <tr>
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Description</th>
            <th className="p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {diets.map((diet) => (
            <tr key={diet._id} className="border-t border-gray-700">
              <td className="p-2">{diet.name}</td>
              <td className="p-2">{diet.description}</td>
              <td className="p-2">
                <button
                  onClick={() => handleEdit(diet)}
                  className="bg-yellow-600 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(diet._id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ManageDiet;
