import React, { useState, useEffect } from "react";

function ManageFood({ foods, setFoods, token }) {
  const [foodName, setFoodName] = useState("");
  const [foodDescription, setFoodDescription] = useState("");
  const [foodPicture, setFoodPicture] = useState(null);
  const [nutritionalInfo, setNutritionalInfo] = useState({});
  const [editingFoodId, setEditingFoodId] = useState(null);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    fetchFoods();
  }, []);

  const fetchFoods = async () => {
    try {
      const res = await fetch(
        "https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods",
        { headers }
      );
      if (res.ok) {
        const data = await res.json();
        setFoods(data);
      } else {
        console.error("Error fetching foods:", await res.text());
      }
    } catch (error) {
      console.error("Network error fetching foods:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", foodName);
    formData.append("description", foodDescription);
    if (foodPicture) formData.append("picture", foodPicture);
    formData.append("nutritionalInfo", JSON.stringify(nutritionalInfo));

    try {
      const url = editingFoodId
        ? `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods/${editingFoodId}`
        : "https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods";

      const method = editingFoodId ? "PUT" : "POST";

      const res = await fetch(url, {
        method,
        headers: { ...headers },
        body: formData,
      });

      if (res.ok) {
        const updatedFood = await res.json();
        if (editingFoodId) {
          setFoods((prevFoods) =>
            prevFoods.map((food) =>
              food._id === updatedFood._id ? updatedFood : food
            )
          );
        } else {
          setFoods((prevFoods) => [...prevFoods, updatedFood]);
        }
        resetForm();
      } else {
        console.error("Error saving food:", await res.text());
      }
    } catch (error) {
      console.error("Network error saving food:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods/${id}`,
        {
          method: "DELETE",
          headers,
        }
      );

      if (res.ok) {
        setFoods((prevFoods) => prevFoods.filter((food) => food._id !== id));
      } else {
        console.error("Error deleting food:", await res.text());
      }
    } catch (error) {
      console.error("Network error deleting food:", error);
    }
  };

  const handleEdit = (food) => {
    setFoodName(food.name);
    setFoodDescription(food.description);
    setNutritionalInfo(food.nutritionalInfo || {});
    setEditingFoodId(food._id);
  };

  const resetForm = () => {
    setFoodName("");
    setFoodDescription("");
    setFoodPicture(null);
    setNutritionalInfo({});
    setEditingFoodId(null);
  };

  const handleNutritionalChange = (key, value) => {
    setNutritionalInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Foods</h2>

      {/* Add/Edit Food Form */}
      <form className="mb-4 bg-gray-800 p-4 rounded-md" onSubmit={handleSubmit}>
        <div className="mb-2">
          <label className="block text-gray-300">Food Name</label>
          <input
            type="text"
            value={foodName}
            onChange={(e) => setFoodName(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            placeholder="Enter food name"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Description</label>
          <textarea
            value={foodDescription}
            onChange={(e) => setFoodDescription(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            placeholder="Enter food description"
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Picture</label>
          <input
            type="file"
            onChange={(e) => setFoodPicture(e.target.files[0])}
            className="w-full p-2 bg-gray-700 text-gray-300"
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Nutritional Information</label>
          {[
            "Calories",
            "Total Fat",
            "Saturated Fat",
            "Cholesterol",
            "Sodium",
            "Potassium",
            "Total Carbohydrate",
            "Dietary Fiber",
            "Sugar",
            "Protein",
            "Vitamin C",
            "Calcium",
            "Iron",
          ].map((nutrient) => (
            <div key={nutrient} className="mb-1">
              <input
                type="text"
                placeholder={`${nutrient} (per 100g)`}
                value={nutritionalInfo[nutrient] || ""}
                onChange={(e) =>
                  handleNutritionalChange(nutrient, e.target.value)
                }
                className="w-full p-2 rounded bg-gray-700 text-gray-300"
              />
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          {editingFoodId ? "Update Food" : "Add Food"}
        </button>
      </form>

      {/* Food List */}
      <table className="w-full bg-gray-800 text-gray-300 rounded-md">
        <thead>
          <tr>
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Description</th>
            <th className="p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {foods.map((food) => (
            <tr key={food._id} className="border-t border-gray-700">
              <td className="p-2">{food.name}</td>
              <td className="p-2">{food.description}</td>
              <td className="p-2">
                <button
                  onClick={() => handleEdit(food)}
                  className="bg-yellow-600 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(food._id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ManageFood;
