import React, { useState } from "react";

const ManageCategories = ({ categories,setCategories,token, }) => {
  const [newCategory, setNewCategory] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  
  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });
      if (res.ok) {
        const addedCategory = await res.json();
        setCategories((prev) => [...prev, addedCategory]);
        setNewCategory("");
      } else {
        console.error("Failed to add category");
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };
  const handleDeleteCategory = async (id) => {
    try {
      const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/categories/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (res.ok) {
        setCategories(categories.filter((category) => category._id !== id));
      } else {
        console.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };
  const handleEditCategory = (category) => {
    setEditCategoryId(category._id);
    setEditCategoryName(category.name);
  };
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/categories/${editCategoryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: editCategoryName }),
      });
      if (res.ok) {
        const updatedCategory = await res.json();
        setCategories(
          categories.map((category) =>
            category._id === editCategoryId ? updatedCategory : category
          )
        );
        setEditCategoryId(null);
        setEditCategoryName("");
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
  <h3 className="text-2xl font-bold mb-6">Manage Categories</h3>

  {/* Category Form */}
  <form
    onSubmit={editCategoryId ? handleUpdateCategory : handleAddCategory}
    className="flex flex-col md:flex-row gap-4 items-center bg-gray-800 p-4 rounded-lg shadow-md"
  >
    <input
      type="text"
      value={editCategoryId ? editCategoryName : newCategory}
      onChange={(e) =>
        editCategoryId
          ? setEditCategoryName(e.target.value)
          : setNewCategory(e.target.value)
      }
      placeholder="Category Name"
      required
      className="w-full md:w-3/4 p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
    />
    <button
      type="submit"
      className="w-full md:w-auto bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded shadow"
    >
      {editCategoryId ? "Update Category" : "Add Category"}
    </button>
  </form>

  {/* Categories List */}
  <ul className="mt-6 space-y-4">
    {categories.map((category) => (
      <li
        key={category._id}
        className="flex justify-between items-center p-4 bg-gray-800 rounded-lg shadow-md"
      >
        <p className="font-medium">{category.name}</p>
        <div className="flex gap-2">
          <button
            onClick={() => handleEditCategory(category)}
            className="px-3 py-1 bg-yellow-500 hover:bg-yellow-400 text-white rounded shadow"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteCategory(category._id)}
            className="px-3 py-1 bg-red-600 hover:bg-red-500 text-white rounded shadow"
          >
            Delete
          </button>
        </div>
      </li>
    ))}
  </ul>
</div>

  );
};

export default ManageCategories;
